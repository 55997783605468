import styled from "styled-components";

export const Button = styled.button`
  max-width: 400px;
  width: fit-content;
  padding: 8px 16px;
  margin: 8px;
  font-size: 1.1rem;
  font-weight: 600;
  color: white;
  background: transparent;
  text-transform: capitalize;
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 17px;
  outline: none;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 100px;
  transition:all .3s;
  a {
      text-decoration: none;
      color:inherit;
      display:flex;
      align-items:center;
    }
  &:hover{
        background-color:#4CAF50;
        background-color:white;
        color:black;




  

`;
