import './App.css';
import IndexPage from './pages/indexPage';

function App() {
  return (
    <div className="App">
     <IndexPage/>

     
    </div>
  );
}

export default App;
