import styled from "styled-components";

export const IndexMainDiv = styled.main`
  overflow: hidden;
  padding: 0 3rem;
  text-align: left;
  border-bottom: 1px solid white;
  padding-bottom: 32px;
  margin-bottom: 32px;
  position:relative;

  > div {
    max-width: 1600px;
    margin: 0 auto;
  }

  @media (max-width: 850px) {
    padding: 0 10px;
  }
`;

export const IndexHero = styled.div`
  //background: linear-gradient(#280925, #270b27);

  margin-bottom: 90px !important;
  > div {
    justify-content: space-evenly;
    align-items: start;

    margin: 0 auto;
    display: flex;
    gap: 16px;
  }
  .start {
    align-items: start;
    width: fit-content;
    gap: 150px;
    margin-bottom: 50px;
    border-bottom: 1px solid white;
  }

  .flex {
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    align-items: center;
  }
  .bottom {
  }
  .reverse {
  }
  .scroll {
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .wrap {
    flex-wrap: wrap;
  }
  @media (max-width: 850px) {
    .flex {
      align-items: center;
      justify-content: center;
    }

    .start {
      gap: 20px;
    }

    .reverse {
      background-image: url("/images/download (5).jpeg");
      background-size: 100% 170%;
      background-position: top;
      background-repeat: no-repeat;
      flex-direction: column-reverse;
      align-items: center;
    }
  }
`;

export const IndexHeroSection = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > section {
    max-width: 700px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  button {
    text-align: center;
    padding: 16px 32px;
  }

  @media (max-width: 850px) {
    width: 100%;

    align-items: center;
    text-align: center;
    padding-bottom: 30px;
    button {
      text-align: center;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 700;
      color: black;
      background: white;
      border-radius: 10px;
      width: 100%;
    }
  }
`;

export const IndexImageHeroSection = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > section {
    max-width: 700px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;
export const IndexHeroHeaderText = styled.h1`
  font-size: 69px;
  line-height: 1.2em;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 32px;
  @media (max-width: 450px) {
    font-size: 49px;
  }
`;

export const IndexImageHero = styled.div`
  height: 426px;
  width: 490px;
  position: relative;
  > div {
    height: 455px;
    width: 524px;
    background: rgb(255, 255, 255);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .imageHero {
    border-radius: 100px;
    border-bottom-left-radius: 240px;
    height: 426px;
    width: 490px;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-position: 10% 10%;

    object-fit: cover;
  }
  @media (max-width: 1220px) {
    width: 390px;
    > div {
      width: 424px;
    }

    .imageHero {
      width: 390px;
    }
  }
  @media (max-width: 860px) {
    display: none;
  }
  @media (max-width: 450px) {
    display: flex;
    justify-content: center;
    align-item: center;
    height: 330px;

    width: 80%;
    > div {
      margin: 0 auto;
      height: 330px;

      width: 100%;
    }

    .imageHero {
      width: 95%;
      border-bottom-left-radius: 140px;

      height: 300px;
    }
  }
`;

export const IndexFeatureHeroHeader = styled.div`
  font-size: 46px;
  font-weight: bold;
  text-transform: uppercase;

  .white {
    color: white;
  }

  > span {
    font-weight: 100;
    border-bottom: 1px solid #2e0139;
    background-color: #4caf50;
  }
  @media (max-width: 450px) {
    font-size: 39px;
  }
`;

export const IndexIntrodctionHeroText = styled.div`
  max-width: 699px;
  margin-bottom: 90px;
`;

export const IndexAboutHero = styled.div`
  > div {
    justify-content: space-evenly;
    align-items: center;
    margin: 10px auto 100px;
    display: flex;
    gap: 16px;
  }
  section {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  @media (max-width: 1220px) {
    > div {
      align-items: start;
    }
  }
  @media (max-width: 860px) {
    section {
      width: 100%;
    }

    p {
      width: 95%;
      margin-left: auto;
      margin-right: auto;
    }
    button {
      text-align: center;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-weight: 700;

      border-radius: 10px;
      width: 100%;
    }
  }
`;

export const IndexAboutIcon = styled.div`
  width: 420px;
  height: 657px;

  overflow: hidden;
  //border-top-left-radius: 240px;
  //border-bottom-left-radius: 100px;
  //border-bottom-right-radius: 240px;
  //border-top-right-radius: 100px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-position: 10% 10%;

    object-fit: cover;
  }
  > div {
    padding-left: 16px;
    position: absolute;
    bottom: 24px;
  }
  @media (max-width: 1220px) {
    width: 350px;
    height: 657px;
  }
  @media (max-width: 860px) {
    display: none;
  }
`;

export const IndexFeatureHeroSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: stretch !important;
  max-width: 1200px;
`;

export const IndexFeatureHeroItem = styled.div`
  flex: 1;
  min-width: 200px;
  min-height: 200px;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  text-align: center;
  text-transform: capitalize;
  border: 1px solid white;
  > p {
    color: #d9d9d9;
  }
  > img {
    width: 40px;
    height: 40px;
  }
`;

export const IndexContainer = styled.div`
  margin-bottom: 90px !important;

  > div {
    justify-content: space-evenly;
    align-items: stretch !important;
    margin: 0 auto;
    display: flex;
    gap: 16px;
  }

  @media (max-width: 850px) {
    .block {
      flex-direction: column;
      align-items: stretch;
    }
    > div {
      padding-top: 30px;
      overflow-x: scroll;
      scrollbar-width: none;
    }
  }
`;

export const WatchShowReelButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
  p {
    text-decoration: underline;
    margin: 0 0 8px;
  }

  > span {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    margin: 0 16px;
    padding-left: 1px;
    border: 1px solid white;
  }
  svg {
    width: 23px;
    height: 23px;
  }
`;

export const IndexNewsArticleHead = styled.section`
  text-align: center;
  margin-bottom: 80px;
  text-transform: capitalize;
`;

export const IndexNewsArticleContainer = styled.section`
  text-transform: capitalize;
  flex: 1;
  flex-grow: 1;
  min-height: 200px;
  max-width: 400px;

  border: 1px solid;
  padding: 16px;

  h2 {
    font-size: 19px;
    background: white;
    color: black;
    width: fit-content;
    padding: 4px 16px;
    border-radius: 100px;
  }

  h1 {
    text-align: left;
    max-width: 280px;
  }

  a {
    font-size: 20px;
    display: flex;
    gap: 24px;
    cursor: pointer;
    color: white;
    text-decoration: underline;
  }
  .imghero {
    margin-top: 26px;
    width: 100%;
    height: 280px;
  }
  @media (max-width: 850px) {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    .imghero {
      margin-top: 26px;
      width: 35%;
      height: 200px;
      > img {
      }
    }
  }

  @media (max-width: 450px) {
    flex-direction: column;

    .imghero {
      width: 100%;
      height: 180px;
    }
  }
`;

export const IndexMatchContainer = styled.section`
  text-transform: capitalize;
  flex: 1;
  min-height: 200px;
  max-width: 400px;
  border: 1px solid white;
  padding: 16px;

  > h2 {
    font-size: 19px;
    background: red;
    width: fit-content;
    padding: 4px 16px;
    border-radius: 100px;
  }

  > h1 {
    text-align: left;
  }

  > a {
    font-size: 20px;
    display: flex;
    gap: 24px;
    cursor: pointer;
    color: white;
    text-decoration: underline;
  }
  > p {
    color: #ffffffb3;
  }
  > div {
    display: flex;
    gap: 16px;
    align-items: center;
  }

  svg {
    width: 24px;
    height: 24px;
    color: white;
  }
  .pfp {
    width: 100px;
    height: 100px;
    overflow: hidden;
    margin: 30px auto;
    border-radius: 100px;
    border: 1px solid white;
  }
  .links {
    display: flex;
    padding: 8px;
    align-items: center;
    border: 1px solid white;
    border-radius: 100px;
  }
`;

export const IndexTestimonialContainer = styled.section`
  text-transform: capitalize;
  flex: 1;
  min-height: 200px;
  max-width: 400px;
  min-width: 290px;
  border: 1px solid white;
  padding: 16px;
  position: relative;
  display:flex;
  justify-content: space-between;
  flex-direction:column;

  
  padding-top: 60px;
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h1 {
    margin-bottom: 0;
  }

  .icon {
    position: absolute;
    border: 2px solid white;
    top: -30px;
    width: 60px;
    left: 10px;
    height: 60px;
    border-radius: 100px;
    background: white;
    color: black;
    justify-content: center;
  }
  .pfp {
    border: 2px solid white;
    overflow: hidden;
    width: 45px;
    height: 45px;
    border-radius: 100px;
  }
  svg {
    width: 32px;
    height: 32px;
  }
`;
