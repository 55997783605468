import {
  IndexAboutHero,
  IndexAboutIcon,
  IndexContainer,
  IndexFeatureHeroHeader,
  IndexFeatureHeroItem,
  IndexFeatureHeroSection,
  IndexHero,
  IndexHeroHeaderText,
  IndexHeroSection,
  IndexImageHero,
  IndexIntrodctionHeroText,
  IndexMainDiv,
  IndexMatchContainer,
  IndexNewsArticleContainer,
  IndexNewsArticleHead,
  IndexTestimonialContainer,
} from "../../style/indexcomponentsstyles/indexCmponentsStyles";
import {
  BoldText,
  HeadingText,
  RegularText,
} from "../../style/indexcomponentsstyles/textStyles";
import { Button } from "../../style/reusable";
import { IoArrowForward } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { FaQuoteLeft } from "react-icons/fa";
import { FaTwitch } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";






import AppHeader from "../header/headerComponent";

const IndexPageComponents = () => {
  return (
    <IndexMainDiv>

      <AppHeader />

      <IndexHero  >
        <div className="reverse" >
          <IndexHeroSection>

            <section>
              <IndexHeroHeaderText>More than just gaming</IndexHeroHeaderText>
              <RegularText>
                Experience the best gaming moments with us.
                <br /> Join our community and take your gaming to the next
                level.
              </RegularText>

              <div className="flex">
                <div>
                  <BoldText>
                    300+
                  </BoldText>
                  <RegularText>
                    Gamers
                  </RegularText>
                </div>
                <div>
                  <BoldText>
                    1000+
                  </BoldText>
                  <RegularText>
                    Matches

                  </RegularText>
                </div>
                <div>
                  <BoldText>
                    50+
                  </BoldText>
                  <RegularText>
                    Active Competitions
                  </RegularText>
                </div>
              </div>
              <div className="flex">
                <Button>
                  <a href="https://gameit9ja.eijsoft.com/" target="-blank"> Get Started </a>
                </Button>
                {
                  //</section>WatchShowReelButton>
                  //</section> <span>                 <CiPlay1 />
                  //</section> </span>
                  //</section> <p> watch showreel</p>
                  //</section>/WatchShowReelButton>

                }


              </div>


            </section>


          </IndexHeroSection>
          <IndexImageHero>
            <div>
              <div className="imageHero">

                <img alt="her" src="/images/IG_blondeku_jpeg.jpeg" loading="lazy"></img>


              </div>
            </div>

          </IndexImageHero>
        </div>
      </IndexHero>

      <IndexHero>
        <div className="start wrap ">
          <IndexFeatureHeroHeader>
            Introduction <br />
            <span className="white">
              To Eijsoft


            </span>
          </IndexFeatureHeroHeader>
          <IndexIntrodctionHeroText>
            <RegularText>
              Welcome to Eijsoft, the ultimate destination for eSports teams and gaming enthusiasts. Join forces with elite players, showcase your skills, and dominate the competitive arena.

            </RegularText>

          </IndexIntrodctionHeroText>


        </div>


        <IndexFeatureHeroSection>

          {
            feturedata.map((item, index) => <IndexFeatureHeroItem>
              <img src={item.icon} alt="" loading="lazy"></img>
              <HeadingText> {item.name} </HeadingText>
              <RegularText> {item.details} </RegularText>
            </IndexFeatureHeroItem>)
          }

        </IndexFeatureHeroSection>
      </IndexHero>

      <IndexAboutHero>
        <div>
          <IndexAboutIcon>
            <img alt="her" src="/images/IG_blondeku_jpeg.jpeg" loading="lazy"></img>


            <IndexFeatureHeroHeader   >
              ABOUT <br />
              < span className="white">
                Eijsoft


              </span>
            </IndexFeatureHeroHeader>



          </IndexAboutIcon>
          <section>
            <IndexFeatureHeroHeader>
              The Ultimate e-Sports <br />
              <span>
                Experience Starts Here.


              </span>
            </IndexFeatureHeroHeader>
            <RegularText>
              Eijsoft stands as the premier eSports hub, dedicated to empowering gamers, building communities, and honoring the competitive spirit. As a top-tier platform in the eSports realm, Eijsoft seamlessly merges passion with professionalism, presenting numerous opportunities for players, teams, and fans to flourish. Our platform allows for showcasing skills, engaging in tournaments, and advancing within the competitive gaming arena.            </RegularText>
            <Button>
              <a href="mailto:info@Eijsoft.com?subject=Ready to get started&body=I am excited to join the adventure!" ><span> Get in touch</span>  <IoArrowForward size={29} /></a>
              

            </Button>

          </section>


        </div>

      </IndexAboutHero>

      <IndexHero>
        <IndexNewsArticleHead>

          <IndexFeatureHeroHeader>
            Unleash the Competition.
            <br />
            <span> Explore Matches
            </span>



          </IndexFeatureHeroHeader>

        </IndexNewsArticleHead>
        <div className="scroll">
          {gamesData.map((item, index) =>
            <IndexMatchContainer id={index + "game"}>
              <h1>{item.title} </h1>

              <RegularText> {item.time} </RegularText>
              <div>
                <div className="pfp">
                  <img src={item.icon1} alt="icon" loading="lazy" />


                </div>

                <h1>vs</h1>
                <div className="pfp">
                  <img src={item.icon2}  alt="icon" loading="lazy" />

                </div>

              </div>
              <div>
                <a className="links" href={item.youtubelink}>
                  <FaYoutube />


                </a>
                <a className="links" href={item.twitclink}>
                  <FaTwitch />


                </a>

                <a className="links" href={item.tiktoklink}>
                  <FaTiktok />


                </a>



              </div>


            </IndexMatchContainer>)}



        </div>


      </IndexHero>

      <IndexContainer>
        <IndexNewsArticleHead>
          <RegularText>News & Articles
          </RegularText>
          <IndexFeatureHeroHeader>
            Latest Industry Insights.

          </IndexFeatureHeroHeader>

        </IndexNewsArticleHead>
        <div className="block">
          {
            newsData.map((item, index) =>
              <IndexNewsArticleContainer>
                <div>
                  <h2>{item.section} </h2>

                  <h1> {item.title} </h1>
                  <a href={item.link}><span> read More</span> <IoArrowForward size={24} /> </a>

                </div>

                <div className="imghero">
                  <img src={item.src} alt="icon" loading="lazy" />
                </div>


              </IndexNewsArticleContainer>)
          }


        </div>
      </IndexContainer>

      <IndexContainer>
        <IndexNewsArticleHead>
          <RegularText>our testimonials

          </RegularText>
          <IndexFeatureHeroHeader>
            What Gamers Are Saying.

          </IndexFeatureHeroHeader>

        </IndexNewsArticleHead>
        <div >
          {
            testimonialsData.map((item, index) =>
              <IndexTestimonialContainer>
                <div className="icon">
                  <FaQuoteLeft />

                </div>
                <RegularText>
                  {item.testimonial}
                </RegularText>
                <hr />
                <div>
                  <div className="pfp">
                    <img src={item.src} alt="icon" loading="lazy"></img>
                  </div>
                  <div>

                    <RegularText>
                    {item.career} 
                    </RegularText>
                  </div>
                </div>



              </IndexTestimonialContainer>)
          }


        </div>
      </IndexContainer>

    </IndexMainDiv>
  );
};

const feturedata = [
  {
    icon: "/images/players.jpeg",
    name: "3 Hundred+",
    details: "active Players  worldwide and growing",
  },
  {
    icon: "/images/blob (2).jpeg",
    name: "40+",
    details: "Professional expert Gamers.",
  }, {
    icon: "/images/blob (3).jpeg",
    name: "1 Million+",
    details: "completed games",
  },
  {
    icon: "/images/blob (1).jpeg",
    name: "1 Million+",
    details: "active Players",
  },
]

const newsData = [{
  section: " News",
  title: "GTA 6 Release Date Confirmed for Fall 2025, Take-Two CEO Optimistic",
  link: "https://gameit9ja/blog/news/gta6-release-date",
  src: "/images/GTA VI Wallpaper 4K.jpeg",
}, {
  section: "News",
  title: "Nintendo Offers First Look at Switch 2 Console, Fans Excited",
  link: "https://gameit9ja/blog/news/switch2-first-look",
  src: "/images/nintendo.jpeg",
}, {
  section: "News",
  title: "FTC Refunds Fortnite Players $72 Million Over Deceptive Purchases",
  link: "https://example.com/news/ftc-fortnite-refund",
  src: "/images/Fortnite Chapter 3 Season 1 Wallpapers Papéis de parede.jpeg",
}, {
  section: "News",
  title: "Marvel Rivals Introduces Human Torch and The Thing, Plus Rank Reset",
  link: "https://example.com/news/marvel-rivals-update",
  src: "/images/marvel rivals.jpeg",
},]


const testimonialsData = [{
  career: " Professional Gamer",
  testimonial: "Been using this site for a while now and I love it. The tournaments are fun and competitive, and the community features make it easy to connect with other players",

  src: "/images/Ghost.jpeg",
}, {
  career: " Professional Gamer",
  testimonial: "Perfect for both casual and pro gamers. Setting up teams and tracking stats is super easy. This site is a must-visit for anyone serious about gaming.",

  src: "/images/pfp.jpeg",
}, {
  career: " Casual Gamer",

  testimonial: "This site brings gamers together like no other. Great tournaments and the ability to see everyone’s stats is a game-changer. Highly recommended!",

  src: "/images/pfp2.jpeg",
}, {
  career: " Casual Gamer",
  testimonial: "Awesome platform for gamers! Tournaments are well-organized and the community is great. Love checking out game stats and teaming up with other players.",

  src: "/images/pfp3.jpeg",
},]


const gamesData = [{
  title: " Legends Collide: The Fight for Gaming Glory.",
  time: "December 29, 2024 10:00 pm",
  icon1: "/images/RealDilz's Pfp.jpeg",
  icon2: "/images/akim.jpeg",
  youtubelink: "",
  twitclink: "",
  tiktoklink: "",

}, {
  title: " Legends Collide: The Fight for Gaming Glory.",
  time: "December 29, 2024 10:00 pm",
  icon1: "/images/cjay.jpeg",
  icon2: "/images/Ghosts (1).jpeg",
  youtubelink: "",
  twitclink: "",
  tiktoklink: "",

}, {
  title: " Legends Collide: The Fight for Gaming Glory.",
  time: "December 29, 2024 10:00 pm",
  icon1: "/images/RealDilz's Pfp.jpeg",
  icon2: "/images/abdul.jpeg",
  youtubelink: "",
  twitclink: "",
  tiktoklink: "",

}, {
  title: " Legends Collide: The Fight for Gaming Glory.",
  time: "December 29, 2024 10:00 pm",
  icon1: "/images/kelvin.jpeg",
  icon2: "/images/MEU NOVO PERFIL.jpeg",
  youtubelink: "",
  twitclink: "",
  tiktoklink: "",

},]
export default IndexPageComponents;
