import { useEffect, useState } from "react"
import logo from "./assets/logo.png"
import { AppHeaderLogo, AppHeaderLogoDiv, AppHeaderMainDiv, AppHeaderNav, } from "../../style/headerStyles/headerStyles"

const AppHeader = () => {
    const [active, setActive]= useState<String>("/")
    useEffect(()=>{
        var loc = window.location.href
        console.log(active);
        setActive(loc)
        
        
    },[active])
    return (
       <AppHeaderMainDiv>
        <AppHeaderLogoDiv>
                <AppHeaderLogo src={logo} alt="Eijsoft" loading="lazy"/>

        </AppHeaderLogoDiv>
        <div>
            <AppHeaderNav>
               

               
                

            </AppHeaderNav>

           
        </div>

       </AppHeaderMainDiv>
    )

}
export default AppHeader