import { useState } from "react";
import { SignUpCtaMainDiv } from "../../style/ctsStyles/signupCtaStyles"
import { RegularText } from "../../style/indexcomponentsstyles/textStyles"
import { Button } from "../../style/reusable"

import { RiCloseLine } from "react-icons/ri";





const SignUpCtaComponents= () =>
{
    const [close,SetClose] = useState<boolean>(true)
   
    
    return(
        <SignUpCtaMainDiv $isActive={close}>
            <section>
            <RegularText> Power up and dive in! Click here to embark on your ultimate gaming adventure! 🎮 </RegularText>
            <Button>
                <a href="https://gameit9ja.eijsoft.com/" target="-blank">Sign up</a>
                 </Button>
            <RiCloseLine  onClick={()=>{
                SetClose(false)
            }}/>


            </section>
       

        </SignUpCtaMainDiv>
        
    )

}

export default SignUpCtaComponents