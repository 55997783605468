import { FooterContent, FooterCopyright, FooterForm, FooterFormContainer, FooterInput, FooterMainDiv, FooterNav, FooterSocialLinks, LetChatMainDiv } from "../../style/footerStyles/footerStyle"
import { HeadingBigText, HeadingText, RegularText } from "../../style/indexcomponentsstyles/textStyles"
import { Button } from "../../style/reusable"
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoYoutube } from "react-icons/io";
import { AiFillTikTok } from "react-icons/ai";



import logo from "./assets/logo.png"



const AppFooter = () => {

    return (
        <FooterMainDiv>

            <LetChatMainDiv>
                <div>
                    <div className="imghero">
                        <img src={logo} alt="Eijsoft" loading="lazy" />

                    </div>
                    <HeadingText>
                        Eijsoft
                    </HeadingText>

                </div>
                <section>
                    <div>
                        <HeadingText>
                            let’s chat today!
                        </HeadingText>
                        <RegularText>Eijsoft is a premier platform dedicated to revolutionizing the eSports industry. We are a team of passionate gamers, developers.</RegularText>
                    </div>

                    <Button><a href="mailto:info@Eijsoft.com?subject=Ready to get started&body=I am excited to join the adventure!">  Get in touch</a></Button>

                </section>
                <hr />





            </LetChatMainDiv>
            <FooterContent>
                <div>

                    <FooterFormContainer>
                        <HeadingBigText>
                            Stay connected w / us.

                        </HeadingBigText>
                        <div>
                            <FooterForm>
                                <FooterInput type="email" id="email" name="email" placeholder="Enter email address"></FooterInput>
                                <Button type="submit">
                                    Subscribe Now
                                </Button>


                            </FooterForm>

                        </div>

                    </FooterFormContainer>



                    <FooterNav>
                        <HeadingBigText> info@Eijsoft.com
                        </HeadingBigText>
                        <div className="footerlinkWrapper">
                            <div className="footerlinks">
                                <a href="/"> About </a>
                                <a href="/"> Contact</a>
                                <a href="/"> Blogs </a>
                            </div>
                            <div className="footerlinks">
                                <a href="/"> Gamers </a>
                                <a href="/"> Matches </a>
                                <a href="/">  Product</a>
                            </div>



                        </div>

                    </FooterNav>



                </div>



            </FooterContent>
            <hr></hr>
            <FooterSocialLinks>
                <div >
                    <a href="https://x.com/gameit9ja?t=tIVo6Hiu3tWS4Y9T53JoaA&s=09" className="icon">
                        <FaTwitter />


                    </a>
                    <a href="https://www.linkedin.com/company/gameit9ja/" className="icon">
                        <FaLinkedin />

                    </a>
                    <a href="https://www.instagram.com/gameit9ja?igsh=ZGUzMzM3NWJiOQ==" className="icon">
                        <  RiInstagramFill />

                    </a>

                    <a href="https://www.tiktok.com/@gameit9ja?_t=8p23SJodFqf&_r=1" className="icon">
                        <  AiFillTikTok />

                    </a>

                    <a href="https://youtube.com/@gameit9ja?si=8gxYIUCy1N9icmxW" className="icon">
                        <  IoLogoYoutube />

                    </a>

                </div>
                <div>
                    <FooterCopyright>
                        ©  2024 <span>Gameit9ja</span> , All Rights Reserved
                    </FooterCopyright>

                </div>

            </FooterSocialLinks>



        </FooterMainDiv>

    )

}

export default AppFooter