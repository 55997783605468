import styled from "styled-components";

export const AppHeaderMainDiv = styled.header`
    padding:1rem 4%;
    display:flex;
    align-items:center;
    justify-content:space-between;
`
export const AppHeaderNav = styled.nav`
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    gap:10px;

`

export const AppHeaderLogoDiv = styled.nav`
  width:150px;
  height:100px;
     

  

`

export const AppHeaderLogo = styled.img`
width:100px;
  height:100px;
    

`
interface AppHeaderNavItemProps {
    active?:string
}
export const AppHeaderNavItem = styled.a<AppHeaderNavItemProps>`
    cursor:pointer;
    transition:all 0.34s;
    font-size:.9em;
    font-weight:600;
    padding-bottom:5px;
    color:var(--text-dark);
    text-decoration:none;
    margin:1rem;
    

    &:hover{
        color:var(--text-white);  
    }

    &.active {
        border-bottom:3px solid var(--cta);
        color:var(--text-white);  
    
  }
    
   
`


export const AppHeaderNavButton = styled.div<AppHeaderNavItemProps>`
    cursor:pointer;
    background: var(--cta);
    height:40px;
    transition:all 0.34s;
    border-radius:50px;
    font-size:.9em;
    font-weight:600;
    color:var(--text-white);
    text-decoration:none;
    padding:.6rem 2rem;
    


    &:hover{
        color:var(--text-dark);  
        padding:.6rem 2.5rem;
    }

    
   
`
