import styled from "styled-components";

export const FooterMainDiv = styled.div`
  background: black;
  color: white;
  padding-top: 100px;
  padding-bottom: 20px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

  > div {
    max-width: 1326px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

export const LetChatMainDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  > hr {
    width: 100%;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 0px;
  }
  .imghero {
    widtg: 80px;
    height: 80px;
  }
  > section {
    text-align: left;
    flex-wrap: wrap;
    display: flex;
    gap: 50px;
    align-items: center;

    justify-content: space-between;
    > div {
      > h1 {
        margin-bottom: 40px !important;
      }
      width: 60%;
    }
  }
  @media (max-width: 450px) {
    > section {
      > div {
        width: 100%;
      }
    }
  }
`;

export const FooterContent = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  h1 {
    margin-bottom: 43px;
  }
`;

export const FooterFormContainer = styled.form`
  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const FooterForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #1a1a1a1a;
  padding-bottom: 20px;
  margin-top: 43px;
  @media (max-width: 450px) {
    flex-wrap: wrap;
  }
`;

export const FooterInput = styled.input`
  min-width: 100px;
  height: 50px;
  width: 80%;
  background: transparent;
  border: none;
  ouline: none;
  font-size: 20px;
  padding: 0;
  color: white;
  &:focus {
    border: none;
    outline: none;
  }
`;

export const FooterNav = styled.div`
  a {
    color: rgb(250, 250, 250);
    text-transform: capitalize;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }
  .footerlinkWrapper {
    justify-content: space-between;
    margin: 0 auto;
    max-width: 265px;
    display: flex;
  }

  .footerlinks {
    grid-row-gap: 27px;
    flex-flow: column;
    display: flex;
  }
  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const FooterSocialLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 30px;
  > div {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .icon {
    width: fit-content;
    background: rgba(254, 254, 254, 0);
    display: flex;
    color: white;
    border-radius: 100px;
    > svg {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 850px) {
    flex: 1;
    justify-content: center;
    align-items: center;
  }
`;

export const FooterCopyright = styled.div`
  @media (max-width: 850px) {
    text-align: center;
    margin-top: 30px;
  }
`;
