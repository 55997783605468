import styled from "styled-components";

export const HeadingBigText = styled.h1`
  font-size: 3rem;
  line-height: 1em;
  text-transform: capitalize;
  font-weight: 800;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  @media (max-width: 1100px) {
    font-size: 36px;
  }
  @media (max-width: 450px) {
    font-size: 28px;
  }
`;
export const HeadingText = styled.h1`
  font-size: 2.4rem;
  font-weight: 800;
  padding: 0;
  margin: 0;
  line-height: 1em;
  text-transform: capitalize;
  @media (max-width: 450px) {
    font-size: 28px;
  }
`;

export const RegularText = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.7em;
  padding: 0;
  margin: 0;
`;

export const BoldText = styled.h1`
  font-weight: 500;
  line-height: 1em;
  padding: 0;
  margin: 0;
  font-size: 50px;
  margin: 0;
`;
