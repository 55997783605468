import SignUpCtaComponents from "../components/cta/signupCta"
import AppFooter from "../components/footer/footerComponent"
import IndexComponents from "../components/indexComponents/indexComponents"

const IndexPage = () => {
    return (
        <>
        <SignUpCtaComponents></SignUpCtaComponents>
        <IndexComponents />
        <AppFooter></AppFooter>

        </>
        
    )

}
export default IndexPage