import styled from "styled-components";

interface SignUpCtaMainDivProp{
    $isActive:boolean
}
export const SignUpCtaMainDiv = styled.div<SignUpCtaMainDivProp>`
display:${(p) =>p.$isActive? "block":"none"};
  position: absolute;
  background-color: white;
  width: 100% !important;
  color: black;
  max-width: 6000px;
  overflow: hidden;
  z-index: 1;
  > section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 10px;
  }
  p,
  button {
    font-size: 16px;
    font-weight: 300;
    text-transform: capitalize;
  }

  p {
  
  overflow:hidden;
    margin-right: 4rem;
  }
  button {
    margin: 2px 14px;
    font-size: 16px;
    color: black;
    border-color: black;
    border-radius: 6px;
    &:hover {
      cursor: pointer;
      background-color: black;
      color: white;
    }
    a {
      text-decoration: none;
      color:inherit;
    }
  }
  svg {
  cursor:pointer;
    position: absolute;
    right: 0;
    top: 25%;
    height: 25px;
    width: 25px;
  }
`;
